<template>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Buscar Aluno</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-sm-3">
        <label for="tipo_pesquisa">Tipo de Pesquisa</label>
        <select id="tipo_pesquisa" class="form-control">
          <option>Nome</option>
          <option>Código</option>
        </select>
      </div>
      <div class="col-sm-8">
        <label>Pesquisa</label>
        <input type="text" class="form-control" v-model="state.nome">
      </div>
      <div class="col-sm-1" style="padding-left:0">
        <button class="btn btn-primary" type="button" style="margin-top:32px" @click="fetchClientes"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
          </svg>
        </button>
      </div>
    </div>

    <div class="row" style="margin-top:15px">
      <div class="col">
        <div class="table-responsive">
          <table class="table tablesorter table align-items-center table-flush">
            <thead class="thead-light">
                <th>Código</th>
                <th>Nome</th>
                <th></th>
            </thead>
            <tbody class="list">
              <corpo-busca-cliente
                @buscarCliente="buscarCliente"
                v-for="cliente in state.clientes"
                :key="cliente.id"
                :cliente="cliente"
                />
            </tbody>
          </table>      
        </div>
      </div>
    </div>


  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
    <button type="button" class="btn btn-primary">Save changes</button>
  </div>
</template>

<script>
import { reactive } from 'vue'
import services from '../../services'
import CorpoBuscaCliente from '../CorpoBuscaClientes'

export default {
  setup (_, { emit }) {

  //  var nome_usuario = window.localStorage.getItem('nome_pessoa')
  //  var id_func_usuario = window.localStorage.getItem('colaborador_id')
    var empresa_id = window.localStorage.getItem('empresa_id')
    var token = window.localStorage.getItem('token')

    
    const state = reactive({
      hasErrors: false,
      isLoading: false,
      clientes:[]
    })


    async function fetchClientes()
    {
      try {
        state.isLoading = true
        const { data } = await services.clientes.clientesPorNome({
            empresa_id:empresa_id,
            token:token,
            nome:state.nome
          })
        console.log(data)
      //  console.log(data)
        state.clientes = data
      //   state.pagination = data.pagination
        state.isLoading = false
        
      } catch (error) {
        handleErrors(error)
      }
    }

    function handleErrors (error) {
      console.log(error)
      state.hasError = !!error
    }

    return {
      fetchClientes,
      state,
      emit,
      CorpoBuscaCliente //,
//      buscarCliente
    }
  },
  emits:['buscarCliente']

}
</script>